






























































import Vue from 'vue';
import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import slider from '../../components/sliderImage/index.vue';
import { $cookies , $axios } from '~/util/api';
import { ResponseCode } from '~/util/constant';

// import slider from '../../../components/sliderImage/index.vue';

export default Vue.extend({
  components: {
    slider,
  },
  asyncData(ctx) {
    const phoneNumber =
      $cookies.get('phoneNumber') ||
      _.get(ctx.store, 'state.global.user.userName', '');
    return {
      phoneNumber: phoneNumber?.toString(),
    };
  },
  data() {
    return {
      loading: false,
      disabled: false,
      phoneNumber: '',
      btnText: this.$t('personal.right.obtain_code'),
      pwdForm: {
        code: '',
        newPsd: '',
      },
      rules: {
        code: [
          { required: true, message: this.$t('personal.right.code_cannot_empty'), trigger: 'blur' },
        ],
        newPsd: [
          { required: true, message: this.$t('personal.right.new_psd_cannot_empty'), trigger: 'blur' },
          { min: 6, message: this.$t('personal.right.password_tooweak'), trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    async handleSendCode() {
      const $sliderImage = this.$refs.sliderImage as ElForm;
      ($sliderImage as any).getDate();
    },
    getCode(id: number) {
      this.sentCode(id);
    },
    async sentCode(id: number) {
      const verCode = id;
      const res: any = await $axios.get(
        `/api/auth/sendRegisterVerifyCode/${this.phoneNumber}/${verCode}`
      );
      this.$data.loading = false;
      if (res.code === ResponseCode.SUCCESS) {
        let s = 60;
        this.disabled = true;
        const timer = setInterval(() => {
          if (s > 0) {
            this.btnText = `${s}${this.$t('personal.right.second_obtain')}`;
            s--;
          } else if (s === 0) {
            this.btnText = this.$t('personal.right.obtain_code');
            this.disabled = false;
            clearInterval(timer); // 清除定时器
          }
        }, 1000);
      }
    },
    handleSubmit() {
      const $pwdForm = this.$refs.pwdForm as ElForm;
      $pwdForm.validate(async (valid, err) => {
        if (!valid) {
          // 校验失败
          const msg_1 = _.get(err, 'newPsd[0].message', '');
          const msg_2 = _.get(err, 'code[0].message', '');
          this.$message({
            message: msg_1 || msg_2 || this.$t('personal.right.param_illegal'),
            type: 'error',
          });
          return;
        }
        const res: any = await this.$axios.post('/api/auth/forget', {
          phone: this.phoneNumber,
          ...this.pwdForm,
        });

        if (res.code === ResponseCode.SUCCESS) {
          window.location.href = '/login';
        }
      });
    },
  },
  head() {
    return {
      title: '修改密码-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
