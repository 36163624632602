







import Vue from 'vue';
import EditVisitor from '~/components/EditVisitor/index.vue';

export default Vue.extend({
  components: { EditVisitor },
  data() {
    return {
      title: '',
    };
  },
  asyncData(ctx) {
    const { id } = ctx.params;

    return {
      title: id ? '编辑参展人信息' : '参展人信息',
      edit: id,
    };
  },
  methods: {},
});
