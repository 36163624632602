















































import _ from 'lodash';
import Vue from 'vue';
import { ResponseCode } from '~/util/constant';
import { uploadFile } from '~/util/oss';

export default Vue.extend({
  asyncData(ctx) {
    const user = _.get(ctx, 'store.state.global.user', {});
    return {
      user,
      nickName: user.nickName || user.userName,
      companyName: user.companyName || user.companyName,
      position: user.position || user.position,
      avatarUrl: user.avatar,
    };
  },
  data(): any {
    return {
      avatarUrl: '',
      nickName: '',
      companyName: '',
      position: '',
      user: {},
    };
  },
  methods: {
    async resetInfo() {
      this.nickName = ''; // _.get(this, '$store.state.global.user.nickName', '');
      await this.$store.dispatch('global/getUser');
      const user = _.get(this, '$store.state.global.user', {});
      this.user = user;
      this.$router.back();
    },
    // 上传前
    beforeAvatarUpload(file: any) {
      const isJPG = /image/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是图片格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 上传
    async handleUpload(option: any) {
      const file = option.file;
      const res: any = await uploadFile(file);
      if (!res) {
        this.$message.error('头像上传失败.');
      } else {
        this.avatarUrl = res;
        // this.handleEditUser('noback');
      }
    },
    validateNickname(value: string) {
      const phoneRegex = /1[3-9]\d{9}/;
      const landlineRegex =
        /((\+?\d{1,3}-?)?0\d{2,3}-\d{2,3})\d{2,3}(-\d{1,4}|$)/;
      const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
      const specialCharRegex = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/;

      return !(
        phoneRegex.test(value) ||
        landlineRegex.test(value) ||
        emailRegex.test(value) ||
        specialCharRegex.test(value)
      );
    },
    // 修改用户信息
    async handleEditUser(type: string = '') {
      const { avatarUrl, nickName, companyName, position } = this;
      if (type === '') {
        if (!nickName) {
          this.$message.warning('请输入用户名.');
          return;
        }
        if (!this.validateNickname(nickName)) {
          this.$message.warning('昵称不能是手机号、电话号、邮箱或包含特殊字符');
          return;
        }
        if (!companyName) {
          this.$message.warning('请输入公司名称.');
          return;
        }
        if (!position) {
          this.$message.warning('请输入职位.');
          return;
        }
      }

      const res: any = await this.$axios.post('/api/user/editInfo', {
        avatar: avatarUrl || _.get(this.$store, 'state.global.user.avatar', ''),
        nickName:
          nickName || _.get(this.$store, 'state.global.user.nickName', ''),
        companyName:
          companyName ||
          _.get(this.$store, 'state.global.user.companyName', ''),
        position:
          position || _.get(this.$store, 'state.global.user.position', ''),
      });
      if (res.code === ResponseCode.SUCCESS) {
        if (type === '') {
          this.resetInfo();
        }
      }
    },
  },
  head() {
    return {
      title: '修改资料-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
