









































































































import Vue from 'vue';
import VisaItem from './components/VisaItem.vue';
import VisaRepayment from './components/VisaRepayment.vue';
import { ResponseCode } from '~/util/constant';
import { visaTypeOption, bizTypeOption } from '~/pages/visa/helper';

export default Vue.extend({
  components: {
    VisaItem,
    VisaRepayment,
  },
  filters: {
    stringToArray(value: string) {
      try {
        return JSON.parse(value);
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return value;
      }
    },
    getTicketStatus(item: any) {
      if (item.ticket.isFreeTicket === 1) {
        return '出票成功';
      }

      if (item.status === 0) {
        return '出票中';
      } else {
        return '出票成功';
      }
    },
  },
  async asyncData(ctx) {
    const pageNum = 1;
    const pageSize = 10;

    const ticketRes: any = await ctx.$axios.post('/api/visaOrderApi/myOrder', {
      pageNum,
      pageSize,
    });
    let ticket = {};
    if (ticketRes.code === ResponseCode.SUCCESS) {
      ticketRes.data.records.map((item: any) => {
        item.visaMainPic = item.visaPic ? item.visaPic.split(',')[0] : '';
      });
      ticket = ticketRes.data;
    }

    return {
      pageNum,
      pageSize,
      ticket,
    };
  },
  data() {
    return {
      visaTypeOption,
      bizTypeOption,
      activeType: '0',
      pageNum: 1,
      pageSize: 10,
      ticket: {
        total: 0,
      },

      EPayStatus: {
        0: '待支付',
        1: '已支付',
        2: '退款中',
        3: '已退款',
      } as any,
      ETabStatus: {
        '0': null,
        '1': 0,
        '2': 1,
        '3': 2,
        '4': 3,
      } as any,
      currentTicket: {},
      repaymentVisible: false,
      show: false,
      maxHeight: 0,
      isTransitioning: false,
    };
  },
  methods: {
    handleTabClick(tab: any) {
      this.activeType = tab.name;
      this.pageNum = 1;
      this.handleGetList();
    },
    // 获取订单列表
    async handleGetList() {
      const { pageNum, pageSize } = this;
      const ticketRes: any = await this.$axios.post(
        '/api/visaOrderApi/myOrder',
        {
          status: this.ETabStatus[this.activeType],
          pageNum,
          pageSize,
        }
      );

      if (ticketRes.code === ResponseCode.SUCCESS) {
        ticketRes.data.records.map((item: any) => {
          item.visaMainPic = item.visaPic ? item.visaPic.split(',')[0] : '';
        });
        this.ticket = ticketRes.data;
      }
    },

    // 换页
    handleChangePage(page: number) {
      this.pageNum = page;
      this.handleGetList();
    },
    handleShowRepayment(item: any) {
      // this.currentTicket = item
      Object.assign(this.currentTicket, { ...item });
      this.repaymentVisible = true;
    },
  },
  head() {
    return {
      title: '我的签证-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
