




























































import Vue from 'vue';
import moment from 'moment';
import _ from 'lodash';
import { handleNewsUrl } from '@/util/common';

export default Vue.extend({
  name: 'NewsItem',
  filters: {
    formatTime(val: string) {
      return moment(new Date(val)).format('YYYY-MM-DD HH:mm:ss');
    },
    description(item: Record<string, any>): any {
      const content = item.description || item.content;
      if (!_.isString(content)) return;

      const reg = /<[^>]+>/gi;
      const description =
        (content ?? '') != ''
          ? content
              ?.replace(reg, '')
              ?.replace(/&nbsp;/g, '')
              ?.trim()
          : '';
      return description?.slice(0, 100) + '...';
    },
  },
  props: {
    news: {
      type: Object,
      default: () => ({}),
    },
    newsType: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    handleNewsUrl,
  },
});
