import { render, staticRenderFns } from "./News.vue?vue&type=template&id=590c0051&"
import script from "./News.vue?vue&type=script&lang=ts&"
export * from "./News.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Pagination: require('/root/workspace/com.jufair.vue_Z6yh/components/Pagination/index.vue').default})
