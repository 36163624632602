

































































import Vue from 'vue';
import moment from 'moment';
import { handleNewsUrl } from '@/util/common';

export default Vue.extend({
  name: 'NewsItem',
  filters: {
    formatTime(val: string) {
      return moment(new Date(val)).format('YYYY-MM-DD HH:mm:ss');
    },
    description(item: Record<string, any>): any {
      if (item.description) {
        return item.description;
      } else {
        const reg = /<[^>]+>/gi;
        const description =
          (item.content ?? '') != ''
            ? item.content
                ?.replace(reg, '')
                ?.replace(/&nbsp;/g, '')
                .trim()
            : '';
        return description ? description?.slice(0, 100) + '...' : '';
      }
    },
  },
  props: {
    news: {
      type: Object,
      default: () => ({
        content: '',
      }),
    },
    newsType: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    handleNewsUrl,
  },
});
