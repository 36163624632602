


































































































































import Vue from 'vue';
import lookIncoive from './components/lookIncoive.vue';
import getIncoive from './components/getIncoive.vue';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  components: {
    lookIncoive,
    getIncoive,
  },
  filters: {
    stringToArray(value: string) {
      try {
        return JSON.parse(value);
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return value;
      }
    },
  },
  async asyncData(ctx) {
    const pageNum = 1;
    const pageSize = 10;
    const { userId } = ctx.store.state.global.user;

    const periodicalRes: any = await ctx.$axios.post(
      '/api/periodical/orderListUser',
      {
        userId,
        pageNum,
        pageSize,
      }
    );
    let periodical = {};
    if (periodicalRes.code === ResponseCode.SUCCESS) {
      periodical = periodicalRes.data;
    }

    return {
      pageNum,
      pageSize,
      periodical,
    };
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      periodical: {},
      // 1未支付 2 已申请 3 已支付
      EStatus: {
        1: this.$t('personal.right.order_status1'),
        2: this.$t('personal.right.order_status2'),
        3: this.$t('personal.right.order_status3'),
        4: this.$t('personal.right.order_status4'),
      },
      invoicePic: '',
      orderId: 0,
      orderType: 1,
      lookIncoiveShow: false,
      getIncoiveShow: false,
    };
  },
  methods: {
    toInvoice(id: number, orderType: number) {
      this.orderId = id;
      this.orderType = orderType;
      this.getIncoiveShow = true;
    },
    async lookInvoice(id: string, orderType: number) {
      const { userId } = this.$store.state.global.user;
      const res: any = await this.$axios.post(
        `/api/invoiceRecordApi/getIdByOrderId`,
        {
          userId,
          orderType,
          orderIds: [id],
        }
      );
      if (res.code === '1') {
        this.invoicePic = res.data.invoicePic;
        this.lookIncoiveShow = true;
      }
    },
    offHandle() {
      this.getIncoiveShow = false;
      this.handleGetList();
    },
    // 获取门票列表
    async handleGetList() {
      const { pageNum, pageSize } = this;
      const { userId } = this.$store.state.global.user;

      const periodicalRes: any = await this.$axios.post(
        '/api/periodical/orderListUser',
        {
          userId,
          pageNum,
          pageSize,
        }
      );

      if (periodicalRes.code === ResponseCode.SUCCESS) {
        this.periodical = periodicalRes.data;
      }
    },
    // 换页
    handleChangePage(page: number) {
      this.pageNum = page;
      this.handleGetList();
    },
    async handleToDetail(periodicalId: number) {
      const periodicalRes: any = await this.$axios.get(
        `/api/periodical/periodicalDetail/${periodicalId}`
      );
      if (periodicalRes.code === ResponseCode.SUCCESS) {
        if (periodicalRes.data.periodical.jump) {
          return window.open(
            `/exhibitors/${periodicalRes.data.periodical.exhibitionId}.html?periodicalId=${periodicalId}`,
            '_blank'
          );
        }
        if (periodicalRes.data.periodical.url) {
          window.open(periodicalRes.data.periodical.url, '_blank');
        } else if (periodicalRes.data.periodical.redirectUrl) {
          window.open(periodicalRes.data.periodical.redirectUrl, '_blank');
        } else {
          this.$message.warning('获取会刊异常，请联系客服处理。');
        }
      } else {
        this.$message.warning('获取会刊异常，请联系客服处理。');
      }
    },
  },
  head() {
    return {
      title: '展商名录-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
