


















import Vue from 'vue';
import NewsItem from './NewsItem.vue';

export default Vue.extend({
  name: 'News',
  components: {
    NewsItem,
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageNum: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    news: {
      type: Array,
      default: () => [],
    },
    newsType: {
      type: Number,
      default: 0,
    },
    industryId: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    changePager(page: number) {
      this.$router.push({
        path: `/information-${this.newsType}-${this.industryId}-${page}/`,
      });
    },
  },
});
